import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Window Magic | Quality & affordable window tinting</title>
        <meta
          name="description"
          content="For decades Window Magic has been the leading name in safe and affordable window tinting within the Wellington region."
        />
      </Helmet>

      <Layout title="Thank you" hidePageHeader={true}>
        <section className="page-section container text-center">
          <h1>Thank you</h1>
          <div class="alert alert-success mt-4">
            Your message has been sent to our email. We will be in touch soon.
          </div>
        </section>
      </Layout>
    </>
  )
}
